<template>
  <div class="field">
    <v-menu v-if="compact" v-model="showMenu" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <label
          tabindex="0"
          :title="selectedSubmitter && selectedSubmitter.name"
          class="cursor-pointer text-base-100 d-flex h-full align-center justify-center"
          v-bind="attrs"
          v-on="on"
          @click="renderDropdown = true"
          @touchstart="renderDropdown = true"
        >
          <v-icon x-small class="me-2" :color="colors[submitters.indexOf(selectedSubmitter)]">mdi-circle</v-icon>
        </label>
      </template>
      <v-list class="submitters-list">
        <v-list-item-group v-model="listModel" color="primary">
          <v-list-item
            v-for="(submitter, index) in submitters"
            :key="submitter.uuid"
            @click.prevent="selectSubmitter(submitter)"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <v-icon x-small class="me-2" :color="colors[index]">
                  mdi-circle
                </v-icon>
                <span>{{ $t(`label.${submitter.name === 'contractor' ? 'second_party' : submitter.name}`) }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- Этот блок готов -->
    <label
      v-else
      ref="label"
      tabindex="0"
      class="w-full d-flex align-center cursor-pointer"
      :class="fieldClass"
      @click.stop="changeSubmitter"
    >
      <v-icon x-small class="me-2" :color="colors[submitters.indexOf(selectedSubmitter)]">mdi-circle</v-icon>
      <span>{{
        $t(`label.${selectedSubmitter.name === 'contractor' ? 'second_party' : selectedSubmitter.name}`)
      }}</span>
      <v-icon class="ml-auto">mdi-swap-horizontal</v-icon>
    </label>
  </div>
</template>

<script>
import { v4 } from 'uuid';

export default {
  name: 'FieldSubmitter',
  inject: ['save'],
  props: {
    submitters: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileView: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    fieldClass: {
      type: String,
      required: false,
      default: '',
    },
    menuClasses: {
      type: String,
      required: false,
      default: 'dropdown-content menu p-2 shadow rounded-box w-full z-10',
    },
  },
  emits: ['input', 'remove', 'new-submitter', 'name-change'],
  data() {
    return {
      renderDropdown: false,
      listModel: 0,
      showMenu: false,
    };
  },
  computed: {
    colors() {
      return [
        '#FF5252',
        '#0000C4',
        'bg-emerald-500',
        'bg-yellow-300',
        'bg-purple-600',
        'bg-pink-500',
        'bg-cyan-500',
        'bg-orange-500',
        'bg-lime-500',
        'bg-indigo-500',
        'bg-red-500',
        'bg-sky-500',
        'bg-emerald-500',
        'bg-yellow-300',
        'bg-purple-600',
        'bg-pink-500',
        'bg-cyan-500',
        'bg-orange-500',
        'bg-lime-500',
        'bg-indigo-500',
      ];
    },
    names() {
      return [
        this.$t('first_party'),
        this.$t('second_party'),
        this.$t('third_party'),
        this.$t('fourth_party'),
        this.$t('fifth_party'),
        this.$t('sixth_party'),
        this.$t('seventh_party'),
        this.$t('eighth_party'),
        this.$t('ninth_party'),
        this.$t('tenth_party'),
        this.$t('eleventh_party'),
        this.$t('twelfth_party'),
        this.$t('thirteenth_party'),
        this.$t('fourteenth_party'),
        this.$t('fifteenth_party'),
        this.$t('sixteenth_party'),
        this.$t('seventeenth_party'),
        this.$t('eighteenth_party'),
        this.$t('nineteenth_party'),
        this.$t('twentieth_party'),
      ];
    },
    selectedSubmitter() {
      return this.submitters.find(e => e.uuid === this.value);
    },
    // iconComponent() {
    //   return this.editable ? PlusIcon : ChevronDownIcon;
    // },
  },
  mounted() {
    this.listModel = this.submitters.indexOf(this.selectedSubmitter);
  },
  methods: {
    changeSubmitter() {
      const submitter = this.submitters.filter(item => item.uuid !== this.value);
      this.$emit('input', submitter[0].uuid);
    },
    selectSubmitter(submitter) {
      this.$emit('input', submitter.uuid);
    },
    remove(submitter) {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('are_you_sure'))) {
        this.$emit('remove', submitter);
      }
    },
    move(submitter, direction) {
      const currentIndex = this.submitters.indexOf(submitter);

      this.submitters.splice(currentIndex, 1);

      if (currentIndex + direction > this.submitters.length) {
        this.submitters.unshift(submitter);
      } else if (currentIndex + direction < 0) {
        this.submitters.push(submitter);
      } else {
        this.submitters.splice(currentIndex + direction, 0, submitter);
      }

      this.selectSubmitter(submitter);

      this.save();
    },
    addSubmitter() {
      const newSubmitter = {
        name: this.names[this.submitters.length],
        uuid: v4(),
      };

      this.submitters.push(newSubmitter);

      this.$emit('input', newSubmitter.uuid);
      this.$emit('new-submitter', newSubmitter);
    },
    closeDropdown() {
      document.activeElement.blur();
    },
  },
};
</script>
